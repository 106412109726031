import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import md from "markdown-it"

import { createMarkup } from "../utils"

import Layout from "../components/layout"
import Seo from "../components/seo"

const converter = md()

const TermsPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
  query TermsQuery {
    allDirectusVariable {
      nodes {
        terms_it
        terms_de
        terms_es
        terms_fr
        terms_en
      }
    }
  }
`)

  const lang = intl.locale
  const node = data.allDirectusVariable.nodes[0]
  const terms = lang ? node[`terms_${lang}`] : node[`terms_it`]
  const markupBody = converter.render(terms)

  return (
    <Layout name="p-error">
      <Seo title={intl.formatMessage({ id: "terms.terms_condition" })} />
      <div className="a-container">
        <div className="p-error-content">
          <h2>{intl.formatMessage({ id: "terms.terms_condition" })}</h2>
          <p>
            <div
              dangerouslySetInnerHTML={createMarkup(markupBody)}
            />
          </p>
        </div>

      </div>
    </Layout>
  )
}

export default injectIntl(TermsPage)
